
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import LocationList from "@/components/widgets/client/Client-Location-List-Widget.vue";
import NewClientLocationModal from "@/components/modals/forms/NewClientLocationModal.vue";

export default defineComponent({
  name: "client-client-location-list",
  components: { LocationList, NewClientLocationModal },
  setup() {
    const store = useStore();
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const clientlocationid = ref<number>();
    const editLocationClick = (id: number) => {
      clientlocationid.value = id;
    };

    return {
      currentClient,
      clientlocationid,
      editLocationClick,
    };
  },
});
